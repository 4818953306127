import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import LoggedLayout from 'layout/Logged';
import { DomainContainer } from './LocationRenderer';

const HomePage = Loadable(lazy(() => import('pages/logged/Home')));

const Cities = Loadable(lazy(() => import('pages/logged/registries/Cities')));
const Companies = Loadable(lazy(() => import('pages/logged/registries/Companies')));
const Domains = Loadable(lazy(() => import('pages/logged/registries/Domains')));
const Profiles = Loadable(lazy(() => import('../pages/logged/registries/Profiles')));
const SystemFunctions = Loadable(lazy(() => import('../pages/logged/registries/SystemFunctions')));
const Users = Loadable(lazy(() => import('../pages/logged/registries/Users')));
const SystemParameters = Loadable(lazy(() => import('../pages/logged/registries/SystemParameters')));
const Sites = Loadable(lazy(() => import('../pages/logged/registries/Sites')));
const Departments = Loadable(lazy(() => import('../pages/logged/registries/Departments')));
const ItemizedLists = Loadable(lazy(() => import('../pages/logged/registries/ItemizedLists')));

const Audits = Loadable(lazy(() => import('../pages/logged/audits/Audits')));
const Products = Loadable(lazy(() => import('../pages/logged/templates/Products')));
const Templates = Loadable(lazy(() => import('../pages/logged/templates/Templates')));
const FillAuditByGrid = Loadable(lazy(() => import('../pages/logged/audits/FillAuditByGrid')));

const ControlPanel = Loadable(lazy(() => import('../pages/logged/controlPanel/ControlPanel')));
const ConnectedUsers = Loadable(lazy(() => import('../pages/logged/controlPanel/components/ConnectedUsers')));
const ReportsConfiguration = Loadable(lazy(() => import('../pages/logged/reporting/ReportsConfiguration')));

const Profile = Loadable(lazy(() => import('../pages/logged/user/Profile')));

const registriesRoutes = [
  { path: '/Registries/Cities', component: <Cities />, exact: true },
  { path: '/Registries/Companies', component: <Companies />, exact: true },
  { path: '/Registries/ConnectedUsers', component: <ConnectedUsers />, exact: true },
  { path: '/Registries/Departments', component: <Departments />, exact: true },
  { path: '/Registries/Domains', component: <Domains />, exact: true },
  { path: '/Registries/ItemizedLists', component: <ItemizedLists />, exact: true },
  { path: '/Registries/Profiles', component: <Profiles />, exact: true },
  { path: '/Registries/Sites', component: <Sites />, exact: true },
  { path: '/Registries/SystemParameters', component: <SystemParameters />, exact: true },
  { path: '/Registries/SystemFunctions', component: <SystemFunctions />, exact: true },
  { path: '/Registries/Users', component: <Users />, exact: true }
];

const templatesRoutes = [
  { path: '/Templates/Audits', component: <Audits />, exact: true },
  { path: '/Templates/Products', component: <Products />, exact: true },
  { path: '/Templates/Templates', component: <Templates />, exact: true },
  { path: '/Templates/FillAuditByGrid', component: <FillAuditByGrid />, exact: true }
];

const controlPanelRoutes = [{ path: '/ControlPanel/Home', component: <ControlPanel />, exact: true }];

const reportingRoutes = [{ path: '/Reporting/ReportsConfiguration', component: <ReportsConfiguration />, exact: true }];

const userRoutes = [{ path: '/User/Profile', component: <Profile />, exact: true }];

const LoggedRoutes = {
  path: '/',
  element: <LoggedLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: '/Home',
      element: <HomePage />
    },
    {
      path: '/ControlPanel/*',
      element: <DomainContainer title={'Pannello di controllo'} routes={controlPanelRoutes} />
    },
    {
      path: '/User/*',
      element: <DomainContainer title={'Utente'} routes={userRoutes} />
    },
    {
      path: '/Registries/*',
      element: <DomainContainer title={'Anagrafiche'} routes={registriesRoutes} />
    },
    {
      path: '/Reporting/*',
      element: <DomainContainer title={'Reporting'} routes={reportingRoutes} />
    },
    {
      path: '/Templates/*',
      element: <DomainContainer title={'Gestione Audit'} routes={templatesRoutes} />
    }
  ]
};

export default LoggedRoutes;
